import './App.css';
import Video from './Video/Video';

function App() {
  return (
    <Video />
  );
}

export default App;
